import { Nullable } from '../typescript';
import { ChatMessageInteractableComponentEnum } from './chat-message-interactable-component.enum';
import { ChatMessageInteractableComponent } from './chat-message-interactable-component.types';
import { ChatToolUsageInterface, ToolEnum } from './chat-tool.types';
import { ChatAnswerTypeEnum, ChatMessageStatusEnum, ChatModelId } from './chat.types';

export interface ChatMessageResponseInterface {
	rating: Nullable<number>; // 0 or 1
	status: ChatMessageStatusEnum;
	rawAnswer: string;
	answer: string;
	timestamp: number;
	toolsUsed: ChatToolUsageInterface<ToolEnum>[]; // Use generic tool usage interface
	suggestions: string[];
	answerType?: ChatAnswerTypeEnum;
	interactableComponent?: ChatMessageInteractableComponent<ChatMessageInteractableComponentEnum>;
}

export interface ChatMessageInterface {
	id: string;
	userPrivateId: string;
	question: string;
	timestamp: number;
	deleted: boolean;
	useMemory: boolean;
	modelId: ChatModelId;
	response: ChatMessageResponseInterface;
	lastEventId: Nullable<string>;
}
