export enum ChatMessageStatusEnum {
	SENDING = 'SENDING',
	WRITING = 'WRITING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

export enum ChatTypeEnum {
	WIDGET = 'WIDGET',
	KNOWLEDGE = 'KNOWLEDGE',
}

export const OPENAI_MODEL_IDS = [
	'gpt-4o',
	'gpt-4o-mini',
	'gpt-4-turbo',
	'gpt-4o-mini-2024-07-18',
	'o1-mini',
	'o3-mini',
] as const;
export type OpenAIModelId = (typeof OPENAI_MODEL_IDS)[number];

export const QWEN_MODEL_IDS = ['qwen-plus', 'qwen-turbo'] as const;
export type QwenModelId = (typeof QWEN_MODEL_IDS)[number];

export type ChatModelId = OpenAIModelId | QwenModelId;
export const CHAT_MODEL_IDS = [...OPENAI_MODEL_IDS, ...QWEN_MODEL_IDS] as const;

export const DEFAULT_CHAT_MODEL_ID = 'gpt-4o-mini-2024-07-18' as const;

export enum ChatAnswerTypeEnum {
	AGENT = 'AGENT',
	NOT_FOUND = 'NOT_FOUND',
}
